import {
  Document,
} from "docx";
import i18n from "i18next";
import moment from "moment";
import {SupportService} from "../../../support";
import DocxService from "../DocxService";
import i18next from "i18next";
import {imageByIndex} from "../../../../helpers/imageByIndex";

class ExportDocxAlerts {
  static dimensions = {};

  static renderAlerts = (alerts = []) => {
    const alertsContent = [];

    alerts.forEach(alert => {

      alertsContent.push(DocxService.createEmptyRow());
      const rows = [
        {header: i18n.t('ID'), value: alert.id},
        {header: i18n.t('Module'), value: alert.module},
        {header: i18next.t('Target ID'), value: alert?.targetId},
        {header: i18next.t('Social Network'), value: alert?.socialNetwork},
        {header: i18next.t('Avatar'), value: alert?.avatar},
        {header: i18next.t('Date'), value: moment(alert.createdTime, 'x').format("DD-MM-YYYY")},
        {
          header: i18next.t('Content'),
          value: alert.alertType === 'FACE_IMAGE'
            ? {
              children: [
                DocxService.createImage(imageByIndex(alert.content), {width: 50, height: 50}, false)
              ]
            }
            : alert.content
        },
        {header: i18next.t('Type'), value: alert.alertType},
        {header: i18next.t('Criteria'), value: alert?.alertCriteria?.keyword || alert.alertCriteria?.faceImageURL || alert.alertCriteria?.auditoryThreshold},
      ];


      alertsContent.push(DocxService.createTable(rows, {width: [30, 70]}));
      alertsContent.push(DocxService.createEmptyRow());
      alertsContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Alerts')),
        ...alertsContent,
      ],
    };
  };



  static renderDoc(data) {
    return new Document({
      sections: [
        this.renderAlerts(data),
      ],
    });
  }
}

export default ExportDocxAlerts;