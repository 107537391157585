import styled, {css} from "styled-components";
import {Link, NavLink} from "react-router-dom";
import {Menu} from "antd";

export const HeaderNavMenu = styled.div`
  width: 100%;
  text-align: center;
  flex-grow: 1
`;

export const NavMenu = styled(Menu)`
  box-shadow: inset 0px -1px 0px #5b687d;
  &.ant-menu.ant-menu-dark{
    
  }
`;

export const NavMenuItem = styled(Menu.Item)`
  background-color: transparent!important;
  color: #3282F7!important;
  position: relative;
  &.ant-menu-item-selected > span > a{
    color: #3282F7!important;
  }
  &.ant-menu-item > span > a.active{
    color: #3282F7!important;
  }
  &.ant-menu-item > span > a{
    color: #C9CED6;
  }
`;

export const MenuLink = styled(NavLink)`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #C9CED6;
  text-transform: uppercase;  
`;

export const ALink = styled.a`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #C9CED6;
  text-transform: uppercase;  
`;

export const AlertIndicator = styled.div`
  height: 16px;
  min-width: 16px;
  background-color: #FFCF80;
  color: black;
  border-radius: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 14px;
  font-size: 10px;
  padding: 0 3px;
  text-align: center;
`;
