import {useContext, useState} from 'react';
import PermissionContext from "./PermissionContext";

const usePermission = (permission) => {
  //const [loading, setLoading] = useState(false);

  const {isAllowedTo} = useContext(PermissionContext);
  return [isAllowedTo(permission)]
}

export default usePermission;