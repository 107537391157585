import React, { useState, useEffect } from "react";
import * as Styled from "./styled";
import {EXT_TITLE, VERSION} from "../../../config/consts";
import moment from "moment";

import { Layout } from 'antd';
const { Footer } = Layout;

const MainFooter = () => {
  const [isButtonVisible, setButtonVisible] = useState(false);

  const headerHeight = 56;

  const toggleButtonVisibility = () => {
    window.pageYOffset > headerHeight
      ? setButtonVisible(true)
      : setButtonVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleButtonVisibility);
    return () => window.removeEventListener("scroll", toggleButtonVisibility);
  }, [headerHeight]);

  const scrollToTop = (e) => {
    e.preventDefault();
    const header = document.getElementById("topnav");
    header && header.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <Footer id="components-anchor-demo-basic" style={{background: '#001529', color: 'white', padding: '14px 50px'}}>
      <Styled.Container>
        <Styled.Copyright>
          <Styled.Logo>
            © {moment().format('YYYY')} <span>{EXT_TITLE} </span> {VERSION}
          </Styled.Logo>
        </Styled.Copyright>
        {isButtonVisible && <Styled.ToTopButton onClick={scrollToTop} />}
      </Styled.Container>
    </Footer>
  );
};

export default MainFooter;