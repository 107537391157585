import React from 'react';
import {Page, Text, View, Document} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
import {SocialIcon} from "../Icons";
import moment from "moment";


const ExportPdfAvatarAudit = ({t, data: exportData}) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>


        <View style={{borderBottom: '1px solid #ececec'}}>
          <Text style={{marginBottom: '10px', fontFamily: 'pdfFont', fontSize: '18px'}}>{t("Avatars Audit Log")}</Text>
          <View style={{}} >
            {
              exportData.map(log =>
                <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginRight: '10px', width: '100%'}}>
                  <View style={{width: '300px'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                      <SocialIcon network={log.domain} />
                      <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{log.login}</Text>
                    </View>
                  </View>
                  <View style={{width: '200px',}}>
                    <Text style={{fontSize: '10px', fontFamily: 'pdfFont'}}>{moment(log.timestamp, 'x').format('DD-MM-YYYY HH:mm')}</Text>
                  </View>
                  <View style={{width: '200px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <Text style={{fontSize: '8px', fontFamily: 'pdfFont'}}>{log.username}</Text>
                  </View>
                  <View style={{width: '270px', }}>
                    <Text style={{fontSize: '10px', fontFamily: 'pdfFont'}}>{log.auditType}</Text>
                  </View>
                </View>
              )
            }
          </View>
        </View>

        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfAvatarAudit);