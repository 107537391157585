import {SupportService} from "../../services/support";
import moment from "moment";
import {imageByIndex, imageByMMIndex} from "../../helpers/imageByIndex";
import i18n from "i18next";
import config from "../../config/config";
import {imageProxy} from "../../helpers/imageProxy";

export const transformPersonProfileData = (personInfo, statistic) => {
  delete statistic?.['comments'];

  const dataObject = Object.assign(personInfo, { commonStatistics: statistic });
  if (dataObject?.socialNetworks?.length) {
    dataObject.socialNetworks = dataObject?.socialNetworks.filter(({socialNetwork}) => socialNetwork)
  }
  //console.log(dataObject, 'dataobj');
  return dataObject;
};

export const transformPersonalInfoData = (personalInfo = {}) => {
  const performedObj = {};

  //phones
  performedObj.phones = [...new Set(personalInfo.phones)];

  //names , networks
  performedObj.names = [];
  performedObj.emails = personalInfo?.emails || [];
  performedObj.statuses = [];
  //performedObj.genders = personalInfo?.genders || [];
  performedObj.genders = [];
  performedObj.passwords = personalInfo?.passwords || [];
  performedObj.telegrams = personalInfo?.hydraTelegramIds || [];


  //lastOnline
  performedObj.lastOnline = [];

  //address
  performedObj.address = [];
  if (personalInfo?.addresses && personalInfo?.addresses?.length > 0) {
    personalInfo?.addresses.forEach(({source = '', data = ''}) => {
      performedObj.address.push({address: data, sn: source})
    });
  }

  //names
  personalInfo?.names?.forEach(({source, data, socialNetwork = null}) => {
    if (data) {
      performedObj.names.push({network: source === 'CRAWLER' && socialNetwork ? socialNetwork : source, name: data});
    }
  })


  personalInfo.sourceInfo.forEach(source => {
    /*if (source?.names?.length) {
      source.names.forEach(name => {
        if (source.source === 'CRAWLER') {
          if (source?.socialNetworkIDs) {
            const [firstNetwork] = source?.socialNetworkIDs;
            if (firstNetwork) {
              const [profile = '', sid = ''] = firstNetwork.split(':');
              if (profile && sid) {
                performedObj.names.push({network: sid, name, hover: profile});
              }
            }
          }
        } else {
          performedObj.names.push({network: source.source, name});
        }
      })
    }*/
    if (source?.emails?.length && personalInfo?.emails === 0) {
      source.emails.forEach(email => {
        if (email) {
          performedObj.emails.push(email);
        }
      })
    }
    /*if (source?.socialNetworkIDs?.length) {
      source.socialNetworkIDs.forEach(snId => {
        const [profile = '', sid = ''] = snId.split(':');
        if (profile && sid) {
          performedObj.networks.push({sid, profile});
        }
      })
    }*/
    if (source?.lastOnlineTime) {
      //source.lastOnlineTime > performedObj.lastOnline && (performedObj.lastOnline = parseInt(source.lastOnlineTime));
      const lastOnlineStatus = moment(source.lastOnlineTime * 1, 'x').isValid()
        ? moment(source.lastOnlineTime, 'x').format('DD-MM-YYYY HH:mm')
        : source.lastOnlineTime;

      if (source.source === 'CRAWLER') {
        if (source?.socialNetworkIDs) {
          const [firstNetwork] = source?.socialNetworkIDs;
          if (firstNetwork) {
            const [profile = '', sid = ''] = firstNetwork.split(':');
            if (profile && sid) {
              performedObj.lastOnline.push({source: sid, status: lastOnlineStatus, hover: profile});
            }
          }
        }
      } else {
        performedObj.lastOnline.push({source: source.source, status: lastOnlineStatus});
      }
    }
    if (source?.status) {
      performedObj.statuses.push({source: source.source, status:source?.status});
    }

    //not needed yet, just show addresses property

    /*if (source?.locationInfo && source?.locationInfo?.length > 0) {
      source.locationInfo.forEach(({address = '', city= '', country = ''}) => {
        if (address || city || country) {
          let performedAddress = '';
          if (!!address) {
            performedAddress += address;
          }
          if (!!city) {
            performedAddress += performedAddress ? `, ${city}` : city;
          }
          if (!!country) {
            performedAddress += performedAddress ? `, ${country}` : country;
          }

          let performedSource = source.source;
          if (source.source === 'CRAWLER') {
            if (source?.socialNetworkIDs) {
              const [firstNetwork] = source?.socialNetworkIDs;
              if (firstNetwork) {
                const [profile = '', sid = ''] = firstNetwork.split(':');
                if (profile && sid) {
                  performedSource = sid;
                }
              }
            }
          }

          performedObj.address.push({
            sn: performedSource,
            address: performedAddress
          });
        }
      })
    }*/
  })

  //imsi
  performedObj.imsi = personalInfo?.imsi;

  //gender
  //performedObj.gender = personalInfo?.genders?.join(', ');
  personalInfo?.genders?.forEach(gender => {
    if (gender.source === 'CRAWLER') {
      if (gender?.socialNetworkIDs) {
        const [firstNetwork] = gender?.socialNetworkIDs;
        if (firstNetwork) {
          const [profile = '', sid = ''] = firstNetwork.split(':');
          if (profile && sid) {
            performedObj.genders.push({source: sid, gender: gender.gender, hover: profile});
          }
        }
      }
    } else {
      performedObj.genders.push({source: gender.source, gender: gender.gender});
    }
    if (gender.source === 'TAMTAM') {
      performedObj.genders = performedObj.genders?.filter(e => e?.gender !== 'unknown');
    }
  })

  //operators
  performedObj.operators = personalInfo?.carrier ? [personalInfo?.carrier] : [];

  //networks
  performedObj.networks = [];
  if (personalInfo?.networks?.length) {
    personalInfo.networks.forEach(({data, socialNetwork, sources = []}) => {
      if (data && socialNetwork) {
        if (socialNetwork === 'MY_MAIL_RU' || socialNetwork === 'MYMAIL') {
          const [prefix, profile] = data.split('/');
          performedObj.networks.push({sid: 'MY_MAIL_RU', profile: data, profileName: profile, prefix, hint: sources.join(', ')});
        } else {
          performedObj.networks.push({sid: socialNetwork, profile: data, hint: sources.join(', ')});
        }
      }
    })
  }


  //countryCode
  performedObj.countryCode = personalInfo?.countryCode;

  console.log('transformPersonalInfoData', performedObj);
  return performedObj;
}

export const transformFriendsData = (apiFriends = []) => {
  /*{
    avatar: image1,
      name: 'Marvin Sullivan',
    profiles: [
    {
      sn: 'LINKEDIN',
      name: 'Marvin Sullivan',
    },
    {
      sn: 'TWITTER',
      name: 'Marvin Twittervan',
    },
  ],
    confidence: '98.50062',
  },*/

  const performedFriends = [];
  apiFriends.forEach(friend => {
    performedFriends.push({
      id: friend.id,
      socialNetwork: friend.socialNetwork,
      friendOf: friend.friendOf,
      avatar: friend.friendImageUrl,
      name: friend.friendName,
      friendProfileId: friend.friendProfileId,
      assignedEntityId: friend?.assignedEntityId,
      profiles: [
        {sn: friend.socialNetwork, name: friend.friendProfileId}
      ]
    });
  })
  return performedFriends;
}

export const transformFriendsStatData = (apiFriendsStat = []) => {
  const performedFriendsStat = {};
  apiFriendsStat.forEach(stat => {
    const id = `${stat.socialNetwork}-${stat.socialProfileId}`;
    stat.stat = stat?.stat?.sort((a, b) => a.lookupRequestItemId - b.lookupRequestItemId);
    performedFriendsStat[id] = {
      ...stat
    };
  })
  return performedFriendsStat;
}

export const transformPostsData = (apiPosts = []) => {
  /*{
      avatar: image1,
      socialNetwork: 'FB',
      name: 'Marvin Sullivan',
      date: '2021/04/11 09:42',
      likes: 1234,
      auditory: 12345,
      text: 'But then Jess hears about GeneticAlly, a buzzy DNA-based  matchmaking  company. She hears about GeneticAlly, a buzzy DNA-based matchmaking company',
      sentiment: 'pos',
      web: true,
    },*/
  const performedPosts = [];
  apiPosts.forEach(post => {
    if (post.socialNetwork === 'TELEGRAM' && post.viewsCount) {
      //console.log('here!');
    }

    performedPosts.push({
      ...post,
    });
  })
  return performedPosts;
}

export const transformPostsFilter = (postsFilters = {}) => {
  const performedFilters = {...postsFilters};
  if (!performedFilters.text) {
    delete performedFilters.text;
  }
  if (performedFilters?.socialNetworks?.length === 0) {
    delete performedFilters.socialNetworks;
  }
  if (performedFilters?.publishedDateFrom === '') {
    delete performedFilters.publishedDateFrom;
  }
  if (performedFilters?.publishedDateTo === '') {
    delete performedFilters.publishedDateTo;
  }
  if (performedFilters?.collectedDateFrom === '') {
    delete performedFilters.collectedDateFrom;
  }
  if (performedFilters?.collectedDateTo === '') {
    delete performedFilters.collectedDateTo;
  }
  return performedFilters;
}

const transformImageResults = imageProgress => {
  const out = {
    imageResults: [],
    imageResultsNetworks: []
  }
  const mm_url = config.api_mm_url;
  const { hostname } = new URL(mm_url);

  imageProgress.forEach(
    ({
       findCloneData = null,
       mmData = null,
       search4Face = null,
       profileDTO = null,
    }) => {
    //findClone
    if (findCloneData) {
      const {firstname, thumbnail, score, userid, city, details} = findCloneData;
      out.imageResults.push({
        userid,
        name: firstname,
        location: city,
        confidence: score,
        avatar: thumbnail,
        profiles: [{sn: 'FINDCLONE', name: firstname}],
        morePhotos: [...details.map(ph => {
          return {
            src: ph.url,
            profileUrl: SupportService.getSocialProfileUrl('VK', ph.userid),
            socialNetwork: 'VK',
            profileId: ph.userid,
          }
        })]
      })
      const found = out.imageResultsNetworks.find(item => item.sn === 'FINDCLONE');
      if (found) {
        found.number++;
      } else {
        out.imageResultsNetworks.push({sn: 'FINDCLONE', number: 1})
      }
    }

    //search4face
    if (search4Face) {
      const {first_name, last_name, city, country, /*photo, */face, score, profile, source} = search4Face;
      out.imageResults.push({
        profile,
        name: `${first_name} ${last_name}`,
        location: city || country ? `${city}${country}` : null,
        confidence: score,
        avatar: source,
        profiles: [{sn: 'SEARCH4FACES', name: `${first_name} ${last_name}`}],
        morePhotos: [{
          src: face,
          profileUrl: profile,
          socialNetwork: SupportService.recognizeSocialNetwork(profile),
          profileId: `${first_name} ${last_name}`,
        }]
      })
      const found = out.imageResultsNetworks.find(item => item.sn === 'SEARCH4FACES');
      if (found) {
        found.number++;
      } else {
        out.imageResultsNetworks.push({sn: 'SEARCH4FACES', number: 1})
      }
    }

    //cicada MM
    if (mmData) {
      const {faces, images, names, socialNetworks, phone} = mmData;
      const [face] = faces;
      const [image] = images;

      out.imageResults.push({
        profile: '',
        name: names?.[0],
        location: null,
        confidence: face?.similarity || null,
        //avatar: imageByIndex(image),
        avatar: imageByMMIndex(image),
        profiles: [{sn: 'CICADA', title: hostname, name: phone}],
        morePhotos: [{
          //src: imageByIndex(image),
          src: imageByMMIndex(image),
          profileUrl: null,
          socialNetwork: socialNetworks?.length ? socialNetworks[0] : null,
          profileId: phone,
        }]
      })

      const found = out.imageResultsNetworks.find(item => item.sn === 'CICADA');
      if (found) {
        found.number++;
      } else {
        out.imageResultsNetworks.push({sn: 'CICADA', title: hostname, number: 1})
      }
    }


    //internal database
    if (profileDTO) {
      const {id, names, photos, socialNetworks, similarity = null} = profileDTO;

      const imageObj = {
        id: id,
        profile: '',
        profileUrl: `/profiler/${id}`,
        name: names?.[0],
        location: null,
        confidence: similarity || 100,
        avatar: imageByIndex(photos?.[0]),
        profiles: [],
        morePhotos: photos.map(photo => {
          return {
            src: imageByIndex(photo),
            profileUrl: `/profiler/${id}`,
          }
        })
      };

      const [profile] = socialNetworks;
      if (profile) {
        const [profileId, socialNetwork] = profile.split(':');
        imageObj.profiles = [{sn: socialNetwork, name: profileId}];
      }

      out.imageResults.push(imageObj);

      const found = out.imageResultsNetworks.find(item => item.sn === 'PROFILER');
      if (found) {
        found.number++;
      } else {
        out.imageResultsNetworks.push({sn: 'PROFILER', number: 1})
      }
    }
  })

  return out;
}

export const transformSearchProgress = (searchProgress = {}, imageProgress = []) => {
  //search results
  const performedSearchProgress = {...searchProgress};

  performedSearchProgress.lastUpdated = performedSearchProgress.lastLookupDate
      ? moment(performedSearchProgress.lastLookupDate).format("DD-MM-YYYY HH:mm:ss")
      : ' - ';
  performedSearchProgress.searchResultsNetworks = [];
  performedSearchProgress.searchResults.forEach(res => {
    const found = performedSearchProgress.searchResultsNetworks.find(item => item.sn === res.socialNetwork);
    if (found) {
      found.number++;
    } else {
      performedSearchProgress.searchResultsNetworks.push({sn: res.socialNetwork, number: 1})
    }

    res.name = res.profileName;
    res.avatar = res.imageIds?.[0] && imageByIndex(res.imageIds?.[0]);
    res.profiles = [{sn: res.socialNetwork, name: res.profileName}];
    if (res.profileId) {
      res.profileUrl = SupportService.getSocialProfileUrl(res.socialNetwork, res.profileId)
    }
    if (res?.confidence) {
      res.morePhotos = [{
        src: res.avatar,
        profileUrl: res.profileUrl,
        socialNetwork: res.socialNetwork,
        profileId: res.profileName,
      }]
    }
  });

  performedSearchProgress.socialCrawlers.forEach(res => {
    res.sn = res.socialNetwork;
    res.name = res.socialId;
    res.lastUpdated = res.updateDate ? moment(res.updateDate).format("DD-MM-YYYY HH:mm:ss") : '-';
    res.time = (res.updateDate && res.createdDate)
      ? SupportService.durationTime(moment(res.updateDate).diff(moment(res.createdDate), 'seconds'))
      : ' - ';
    /*if (res?.progress === 0 && res?.status === 'ADDED') {
      res.progress = 100;
    }*/
  });

  performedSearchProgress.nameCrawlers.forEach(res => {
    res.sn = res.socialNetwork;
    res.lastUpdated = res.updateDate ? moment(res.updateDate).format("DD-MM-YYYY HH:mm:ss") : '';
    res.time = (res.updateDate && res.createdDate)
      ? SupportService.durationTime(moment(res.updateDate).diff(moment(res.createdDate), 'seconds'))
      : ' - ';
  });

  performedSearchProgress.names = performedSearchProgress.names?.sort((a, b) => a?.lookupRequestId ? 1 : -1);


  //image results
  performedSearchProgress.imageResults = [];
  performedSearchProgress.imageResultsNetworks = [];

  const {imageResults, imageResultsNetworks} = transformImageResults(imageProgress);
  performedSearchProgress.imageResults = imageResults.sort((a, b) => b?.confidence - a?.confidence);
  performedSearchProgress.imageResultsNetworks = imageResultsNetworks;
  console.log('performedSearchProgress', performedSearchProgress);

  return performedSearchProgress;
}

export const transformPreferencesData = (apiPreferences = []) => {
  const performedPreferences = [];

  apiPreferences.forEach(info => {
    const newInfo = {};

    newInfo.sid = info.socialNetwork;
    newInfo.profileId = info?.profileId;
    newInfo.preferences = [];
    delete info.socialNetwork;
    delete info.profileId;

    for (const title in info) {
      const newItem = {};
      const currentItem = info[title];
      //console.log('currentItem', currentItem)

      newItem.title = SupportService.getTitle(title);
      newItem.type = 'cards';
      newItem.items = [];

      if (newInfo.sid === 'TWITTER') {
        if (title === 'likes') {
          newItem.type = 'timeline';
          newItem.items = currentItem;
        }

        if (title === 'lists') {
          currentItem.forEach(({listName, listUrl}) => {
            newItem.items.push({
              type: 'card',
              img: null,
              subTitle: listName,
              url: listUrl,
            });
          })
        }
      }
      if (newInfo.sid === 'MY_MAIL_RU') {
        if (title === 'groups') {
          currentItem.forEach(({group_name, group_description = '', group_total_members = '', group_url}) => {
            if (group_name || group_url) {
              newItem.items.push({
                type: 'card',
                img: null,
                subTitle: group_name,
                url: group_url,
                subInfo: `${group_description}${group_total_members && " • "}${group_total_members} ${group_total_members && " " + i18n.t('members')}`
              });
            }
          })
        }
        if (title === 'videos') {
          currentItem.forEach(({video_duration, video_name = null, video_title, video_url}) => {
            newItem.items.push({
              type: 'card',
              img: null,
              subTitle: video_title || video_name,
              subInfo: video_duration,
              url: video_url,
            });
          })
        }
        if (title === 'musics') {
          currentItem.forEach(({music_artist, music_title, music_url}) => {
            newItem.items.push({
              type: 'card',
              img: null,
              subTitle: music_artist,
              subInfo: music_title,
              url: music_url,
            });
          })
        }
      }
      if (newInfo.sid === 'OK') {
        if (title === 'groups') {
          currentItem.forEach(({group_name, group_thumbnail_url = null, group_url}) => {
            newItem.items.push({
              type: 'card',
              img: group_thumbnail_url,
              subTitle: group_name,
              url: group_url,
            });
          })
        }
        if (title === 'games') {
          currentItem.forEach(({game_name, game_image_url = null, game_url}) => {
            newItem.items.push({
              type: 'card',
              img: game_image_url,
              subTitle: game_name,
              url: game_url,
            });
          })
        }
        if (title === 'videos') {
          currentItem.forEach(({video_duration, video_title, video_url}) => {
            newItem.items.push({
              type: 'card',
              img: null,
              subTitle: video_title,
              subInfo: video_duration,
              url: video_url,
            });
          })
        }
        if (title === 'gifts') {
          currentItem.forEach(({gift_from, gift_image_url}) => {
            newItem.items.push({
              type: 'card',
              img: SupportService.getImageSrc(gift_image_url),
              subTitle: gift_from,
            });
          })
        }
        if (title === 'music') {
          currentItem.forEach(({artist_name, music_title}) => {
            newItem.items.push({
              type: 'card',
              img: null,
              subTitle: music_title,
              subInfo: artist_name
            });
          })
        }
      }

      if (newInfo.sid === 'VK') {
        if (title === 'groups') {
          currentItem.forEach(({group_name, group_thumbnail = null, group_url}) => {
            newItem.items.push({
              type: 'card',
              img: group_thumbnail,
              subTitle: group_name,
              url: group_url,
            });
          })
        }
        if (title === 'gifts') {
          currentItem.forEach(({gift_author_message, gift_image_url, gift_timestamp}) => {
            newItem.items.push({
              type: 'card',
              img: SupportService.getImageSrc(gift_image_url),
              subTitle: gift_author_message,
              subInfo: gift_timestamp
            });
          })
        }
        if (title === 'followers') {
          currentItem.forEach(({follower_name, follower_thumbnail, follower_url}) => {
            newItem.items.push({
              type: 'card',
              img: SupportService.getImageSrc(follower_thumbnail),
              subTitle: follower_name,
              url: follower_url
            });
          })
        }
      }

      if (newInfo.sid === 'INSTAGRAM') {
        if (title === 'highlights') {
          currentItem.forEach(hi => {
            Object.entries(hi).forEach(([name, img]) => {
              newItem.items.push({type: 'card', img: imageProxy(img), subTitle: name});
            })
          });
        }
      }

      if (Array.isArray(currentItem)) {
        currentItem.forEach(({linkUrl, name, thumbnailUrl, verified}) => {
          if (name || thumbnailUrl) {
            newItem.items.push({type: 'card', img: thumbnailUrl, subTitle: name, url: linkUrl});
          }
        })
        currentItem.forEach(({LinkUrl, Name, Duration, ThumbnailUrl, verified}) => {
          if (Name || ThumbnailUrl || Duration) {
            newItem.items.push({type: 'card', img: ThumbnailUrl, subTitle: Name || Duration, url: LinkUrl});
          }
        })
      }
      newInfo.preferences.push(newItem);
    }
    if (newInfo.preferences?.length > 0) {
      performedPreferences.push(newInfo);
    }
  });

  console.log('performedPreferences', performedPreferences);

  return performedPreferences;
}

export const transformContactInfoData = (apiContactInfo = []) => {
  const performedContactInfo = [];

  apiContactInfo.forEach(info => {
    //remove highlights
    delete info?.highlights;

    const newInfo = {};
    newInfo.sid = info.socialNetwork;
    newInfo.profileId = info?.profileId;
    newInfo.contactInfo = [];
    for (const title in info) {
      const newItem = {};
      const currentItem = info[title];

      newItem.title = SupportService.getTitle(title);
      newItem.items = [];

      if (typeof currentItem === 'string' || typeof currentItem === 'number') {
        newItem.items.push({info: currentItem})
      } else if (Array.isArray(currentItem)) {
        //console.log('currentItem array', currentItem);
        switch (title) {
          case 'education':
            currentItem.forEach(school => {
              newItem.items.push({type: 'card', img: school.schoolImageUrl, subTitle: school.schoolName, info: school.educationLevel, subInfo: school?.otherInfo?.join(', ')});
            });
            break;
          case 'familyMembers':
            currentItem.forEach(member => {
              newItem.items.push({type: 'card', img: member.thumbnailUrl, subTitle: member.name, info: member.fbId, url: member.linkUrl,  subInfo: member.relationship});
            });
            break;
          case 'skills':
            currentItem.forEach(item => {
              newItem.items.push({subTitle: item});
            });
            break;
          case 'highlights':
            currentItem.forEach(hi => {
              Object.entries(hi).forEach(([name, img]) => {
                newItem.items.push({type: 'card', img, subTitle: name});
              })
            });
            break;
          case 'work':
            currentItem.forEach(work => {
              newItem.items.push({type: 'card', img: null, subTitle: work.companyName, info: work.jobTitle, subInfo: work?.otherInfo?.join(', ')});
            });
            break;
          default:
            if (typeof currentItem === 'object') {
              currentItem.forEach(item => {
                newItem.items.push({type: 'object', item});
              });
            }
            break;
        }
      } else if (typeof currentItem === 'object') {
        for (const subTitle in currentItem) {
          switch (subTitle) {
            case 'family_members':
              currentItem?.[subTitle]?.forEach(member => {
                newItem.items.push({type: 'card', img: member?.thumbnailUrl, subTitle: member.name, info: member?.fbId, url: member?.linkUrl,  subInfo: member?.relationship});
              });
              break;
            case 'website':
              if (Array.isArray(currentItem[subTitle])) {
                currentItem[subTitle].forEach(website => {
                  newItem.items.push({subTitle: SupportService.getTitle(subTitle), info: website})
                })
              } else {
                newItem.items.push({subTitle: SupportService.getTitle(subTitle), info: currentItem[subTitle]})
              }
              break;
            default:
              newItem.items.push({subTitle: SupportService.getTitle(subTitle), info: currentItem[subTitle]})
              break;
          }
        }
      }

      newInfo.contactInfo.push(newItem);
    }

    performedContactInfo.push(newInfo);
  });

  console.log('performedContactInfo', performedContactInfo);

  return performedContactInfo;
}

export const transformDeepWeb = (apiDeepWeb = []) => {
  const performedDeepWeb = Object.assign(apiDeepWeb, {});
  const hydraNewData = [];
  if (performedDeepWeb?.hydraItems?.length) {
    performedDeepWeb.hydraItems.forEach(hydraItem => {
      const performedNewData = {
        //orig: hydraItem,
        id: hydraItem.id,
        isProfileDataAdded: hydraItem.isProfileDataAdded,
      }
      const performedDisplayData = {...hydraItem};
      delete performedDisplayData.id;
      delete performedDisplayData.isProfileDataAdded;
      delete performedDisplayData.customRequestId;
      delete performedDisplayData.type;
      if (performedDisplayData?.socialNetworks && Array.isArray(performedDisplayData?.socialNetworks)) {
        const listOfSocialNetworks = performedDisplayData?.socialNetworks?.[0];
        if (listOfSocialNetworks) {
          Object.entries(listOfSocialNetworks)?.forEach(([key, value]) => {
            if (key && value) {
              performedDisplayData[key] = value;
            }
          })
        }
        delete performedDisplayData.socialNetworks;
      }

      performedNewData.display = performedDisplayData;

      hydraNewData.push(performedNewData)
    })
    performedDeepWeb.hydraNewData = hydraNewData;
  }
  console.log('performedDeepWeb', performedDeepWeb)

  return performedDeepWeb;
}

export const transformGeneralStat = (apiGeneralStat = []) => {
  const performedGeneralStat = {};
  const translations = {
    friends: i18n.t("Friends"),
    likes: i18n.t("Likes"),
    mentions: i18n.t("Mentions"),
    posts: i18n.t("Posts"),
  }

  //sentiment
  performedGeneralStat.sentiment = [];
  for (const i in apiGeneralStat.sentiment) {
    performedGeneralStat.sentiment.push({
      //type: SupportService.getFirstLetterBig(i.toLowerCase()),
      type: translations?.[i],
      value: apiGeneralStat.sentiment[i],
      color: SupportService.getChartColor(i),
    });
  }

  //posts
  performedGeneralStat.post = [];
  for (const i in apiGeneralStat.post) {
    performedGeneralStat.post.push({
      //type: SupportService.getFirstLetterBig(i.toLowerCase()),
      type: translations?.[i],
      value: apiGeneralStat.post[i],
      color: SupportService.getChartColor(i),
    });
  }

  //social network
  performedGeneralStat.socialNetwork = [];
  for (const i in apiGeneralStat.socialNetwork) {
    performedGeneralStat.socialNetwork.push({
      //type: SupportService.getFirstLetterBig(i.toLowerCase()),
      type: translations?.[i],
      value: apiGeneralStat.socialNetwork[i],
      color: SupportService.getChartColor(i),
    });
  }

  console.log('performedGeneralStat', performedGeneralStat);

  return performedGeneralStat;
}
export const transformActivityStat = (apiActivityStat = []) => {
  const translations = {
    friends: i18n.t("Friends"),
    likes: i18n.t("Likes"),
    mentions: i18n.t("Mentions"),
    posts: i18n.t("Posts"),
    comments: i18n.t("Comments"),
  }

  const performedActivityStat = [];

  //activity
  for (const i in apiActivityStat) {
    performedActivityStat.push({
      //type: SupportService.getFirstLetterBig(i.toLowerCase()),
      type: translations?.[i],
      value: apiActivityStat[i],
      color: SupportService.getChartColor(i),
    });
  }

  return performedActivityStat;
}

export const transformPostFilters = (filters = {}) => {
  const performedPostFilters = {};
  if (filters.start) {
    performedPostFilters.start = filters.start;
  }
  if (filters.end) {
    performedPostFilters.end = filters.end;
  }
  return performedPostFilters;
}
export const transformPostStat = (apiPostStat = {}, postFilters = {}) => {
  const performedPostStat = [];
  for (const day in apiPostStat) {
    const {shares, comments, likes, posts} = apiPostStat[day];
    performedPostStat.push({type: i18n.t('Posts'), value: posts, date: day,});
    performedPostStat.push({type: i18n.t('Comments'), value: comments, date: day,});
    performedPostStat.push({type: i18n.t('Likes'), value: likes, date: day,});
    performedPostStat.push({type: i18n.t('Shares'), value: shares, date: day,});
  }
  const sortedData = [...performedPostStat.sort(
    (a, b) => moment(a.date, "YYYY-MM-DD HH:mm") - moment(b.date, "YYYY-MM-DD HH:mm")
  )]
  const diff = moment(postFilters.end, 'x')?.diff(moment(postFilters.start, 'x'), 'days') || 7;
  performedPostStat.forEach(stat => {
    stat.date = diff > 1
      ? moment(stat.date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
      : moment(stat.date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm");
  })


  //console.log('transformPostStat', sortedData, postFilters);
  /*const rangeDays = [];

  let {start, end} = postFilters;

  while(moment(start, 'x') <= moment(end, 'x')){
    rangeDays.push(moment(start, 'x').format("YYYY-MM-DD"));
    start = moment(start, 'x').add(1, 'days');
  }
  //console.log('rangeDays', rangeDays);

  rangeDays.map(day => {
    performedPostStat.push({
      type: 'Published',
      value: apiPostStat[day] || 0,
      date: day,
    })
  })*/

  return sortedData;
}

export const prepareFetchFriendRequest = (friend = {}) => {
  //REFERENCE JSON
  /*{
    items: [
      {
        "lookupPhoneNumber":"",
        "lookupEmails": ["email"],
        "lookupNames": ["lookupNames"],
        "socialProfiles": [
          {
            "profileId": "",
            "socialNetwork": "",
            "avatarId": ""
          }],
        "lookupFilters": [
          {
            "type":"",
            "socialNetwork":"",
            "value":""
          }],
        "lookupAvatars": [
          {
            "socialNetwork":"",
            "avatarId": ""
          }]
      }
    ]
  }*/

  const formData = new FormData();

  const items = [];

  const item = {};

  //BY SOCIAL NETWORK

  item.socialProfiles = [];
  item.socialProfiles.push({
    profileId: friend.friendProfileId,
    socialNetwork: friend.socialNetwork,
    avatarId: friend?.avatarId
  })

  items.push(item);
  formData.append("data", JSON.stringify({items}));

  return formData;
}

export const prepareAnalyzeContent = content => {
  console.log('content pre', content);
  let txtContacts = content;
  txtContacts = txtContacts.replace(/[\u0250-\ue007]/g, '');
  txtContacts = txtContacts.replace(/[^\w\s]/gi, '');
  txtContacts = txtContacts.replace(/[\n,\r]/g, ' ');
  txtContacts = txtContacts.replace(/ +(?= )/g, ' ');
  txtContacts = txtContacts.replace(/(\b(\w{20,})\b(\s|$))/g, '');
  console.log('content after', txtContacts);

  return txtContacts;
}

export const transformHistory = apiHistory => {
  const history = [];
  console.log('transformHistory', apiHistory);

  apiHistory.forEach(({timeStamp, lookupResult, imageResult, crawlerResults, webResults}) => {
    if (
      (!lookupResult || lookupResult?.length === 0) &&
      (!crawlerResults || crawlerResults?.length === 0) &&
      (!imageResult || imageResult?.length === 0) &&
      (!webResults || webResults?.length === 0)
    ) {
      return ;
    }

    const item = {
      timeStamp,
      date: moment(timeStamp, 'x').format("DD/MM/YYYY HH:mm:ss"),
      personalInfo: null,
      searchResults: [],
      webResults: [],
    };
    item.activeTab = '';
    if (lookupResult) {
      item.activeTab = 'info';
    } else if (crawlerResults?.length || imageResult) {
      item.activeTab = 'search';
    } else if (webResults) {
      item.activeTab = 'web';
    }

    if (lookupResult) {
      item.personalInfo = {};
      item.personalInfo.foundNames = lookupResult.names?.join(', ');
      item.personalInfo.phones = lookupResult.phones?.join(', ');
      item.personalInfo.operator = lookupResult.operator;
      item.personalInfo.lastOnline = lookupResult.lastOnline;
      item.personalInfo.images = lookupResult.images?.filter(img => img !== '-1')?.map(img => imageByIndex(img));
    }

    if (crawlerResults) {
      crawlerResults.forEach(res => {
        item.searchResults.push({
          name: res.profileName,
          avatar: res.imageIds?.[0] && imageByIndex(res.imageIds?.[0]),
          profiles: [{sn: res.socialNetwork, name: res.profileName}]
        })
      });
    }
    if (imageResult) {
      const {imageResults} = transformImageResults(imageResult);
      item.searchResults = [...item.searchResults, ...imageResults];
    }

    if (webResults) {
      item.webResults = [...webResults];
    }

    history.push(item);
  })

  const sortedHistory = [...history.sort(
    (a, b) => b?.timeStamp - a?.timeStamp
  )];

  return sortedHistory;
}


if (!String.prototype.customReplace) {
  // eslint-disable-next-line
  String.prototype.customReplace = function (regex, callback) {
    return this.replaceAll(regex, value => {
      if (!/^#\d+$/.test(value)) return callback(value);
      return value;
    })
  }
}


const searchEmails = text => {
  const found = text ? text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) : false;
  if (found && found.length) {
    found.forEach(f => {
      text = text.replace(f, `<span class="highlight-email">${f}</span>`);
    })
  }
  return {text, found: found? found.length : false};
};
const searchPhones = text => {
  // eslint-disable-next-line
  const found = text ? text.match(/([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})/gi) : false;
  if (found && found.length) {
    found.forEach(f => {
      text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
    })
  }
  return {text, found: found? found.length : false};
};
const searchHashtags = text => {
  const orig = `${text}`;
  // eslint-disable-next-line
  const regexp = /#([^#^\s^\>^\\^\<^\:^\,^\.^\;^\-^\'^\"^\!^\—^\(^\)^\[^\]^\…^\?^\”^\’^\/]+)/ig;

  const found = text ? text.match(regexp) : false;
  if (found && found.length) {
    /*found.map(f => {
        text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
    })*/
    text = text.customReplace(regexp, value => `<span class="highlight-phone">${value}</span>`);
  }
  return {text, found: found ? (found.length && orig !== text) : false};
};
const searchUrls = text => {
  const found = text ? text.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi) : false;
  if (found && found.length) {
    found.forEach(f => {
      text = text.replace(f, `<span class="highlight-phone"><a href="${f}" target="_blank">${f}</a></span>`);
    })
  }
  return {text, found: found? found.length : false};
};
const searchSocials = text => {
  if (!text) {
    return {text, found: false};
  }
  let count = 0, found = [];
  found = text.match(/http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/gi);
  if (found && found.length) {
      count++;
      found.forEach(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/http(s)?:\/\/(www\.)?github\.com\/[A-z0-9_-]+\/?/gi);
  if (found && found.length) {
      count++;
      found.forEach(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/gi);
  if (found && found.length) {
      count++;
      found.forEach(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  //found = text.match(/http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/gi);
  found = text.match(/(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/ig);
  if (found && found.length) {
      count++;
      found.forEach(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/gi);
  if (found && found.length) {
      count++;
      found.forEach(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/gi);
  if (found && found.length) {
      count++;
      found.map(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/(http:\/\/|https:\/\/)?(www.)?(vk\.com|vkontakte\.ru)\/(id\d|[a-zA-Z0-9_.])+/gi);
  if (found && found.length) {
      count++;
      found.map(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/https?:\/\/(www\.)?ok\.ru\/profile\/\d+/gi);
  if (found && found.length) {
      count++;
      found.map(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }
  found = text.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gi);
  if (found && found.length) {
      count++;
      found.map(f => {
          text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
      })
  }

  found = text ? text.match(/@([^@^\s]+)/g) : false;
  if (found && found.length) {
    count++;
    found.forEach(f => {
      text = text.replace(f, `<span class="highlight-phone">${f}</span>`);
    })
  }

  return {text, found: count};
};

export const localFilterPosts = (posts, type) => {
  switch (type) {
    case 'email':
      posts.forEach(post => {
        const {text, found} = searchEmails(post.text);
        if (found) {
          post.html = text;
          post.isFiltered = found;
        }
      });
      return posts.filter(post => post.isFiltered);
    case 'phone':
      posts.forEach(post => {
        const {text, found} = searchPhones(post.text);
        if (found) {
          post.html = text;
          post.isFiltered = found;
        }
      });
      return posts.filter(post => post.isFiltered);
    case 'hashtag':
      posts.forEach(post => {
        const {text, found} = searchHashtags(post.text);
        if (found) {
          post.html = text;
          post.isFiltered = found;
        }
        if (post.sharedText) {
          const {text: sharedText, found: foundShared} = searchHashtags(post.sharedText);
          if (foundShared) {
            post.sharedHtml = sharedText;
            post.isFiltered = foundShared;
          }
        }
      });
      return posts.filter(post => post.isFiltered);
    case 'url':
      posts.forEach(post => {
        const {text, found} = searchUrls(post.text);
        if (found) {
          post.html = text;
          post.isFiltered = found;
        }
        if (post.sharedText) {
          const {text: sharedText, found: foundShared} = searchUrls(post.sharedText);
          if (foundShared) {
            post.sharedHtml = sharedText;
            post.isFiltered = foundShared;
          }
        }
        if (post?.externalLinkUrl?.length > 0) {
          post.isFiltered = true;
        }
      });
      return posts.filter(post => post.isFiltered);
    case 'profile':
      posts.forEach(post => {
        const {text, found} = searchSocials(post.text);
        if (found) {
          post.html = text;
          post.isFiltered = found;
        }
      });
      return posts.filter(post => post.isFiltered);
    default:
      posts = [];
  }

  return posts;
}

export const transformLocations = (data) => {
  const arrayToReturn = [];
  data.forEach(el => {
    if (el?.postLocations?.length > 0) {
      el.postLocations?.forEach(pel => {
        arrayToReturn.push({
          city: pel.city || pel.country || null,
          country: pel.country || pel.city || null,
          postUrl: pel.postUrl,
          source: el?.source,
        })
      })
    } else {
      arrayToReturn.push({
        city: el.city,
        country: el.country,
        source: el?.source,
      })
    }
  })
  return arrayToReturn
}