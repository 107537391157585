import styled from "styled-components";

export const Header = styled.div`  
`;

export const HeaderMenu = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
`;