import PopupReducer from "./popup/popupReducer";
import SettingsReducer from "./settings/settingsReducer";
import AuthReducer from "./auth/authReducer";
import UsersReducer from "./users/usersReducer";
import InvestigationReducer from "./investigation/investigationReducer";
import ProfilerReducer from "./profiler/profilerReducer";
import AvatarsReducer from "./avatars/avatarsReducer";
import AmmReducer from "./amm/ammReducer";
import AvatarReducer from "./avatar/avatarReducer";
import RekognitionReducer from "./rekognition/rekognitionReducer";
import AlertsReducer from "./alerts/alertsReducer";
import WebintReducer from "./webint/webintReducer";
import AuditReducer from "./audit/auditReducer";
import ProxyReducer from "./proxy/proxyReducer";

export const reducers = {
  auth: new AuthReducer().reducer,
  popup: new PopupReducer().reducer,
  settings: new SettingsReducer().reducer,
  users: new UsersReducer().reducer,
  investigation: new InvestigationReducer().reducer,
  profiler: new ProfilerReducer().reducer,
  webint: new WebintReducer().reducer,
  avatars: new AvatarsReducer().reducer,
  amm: new AmmReducer().reducer,
  avatar: new AvatarReducer().reducer,
  rekognition: new RekognitionReducer().reducer,
  alerts: new AlertsReducer().reducer,
  audit: new AuditReducer().reducer,
  proxy: new ProxyReducer().reducer,
};
