import React, {useContext} from "react";
import { withRouter } from "react-router-dom";
import * as Styled from "./styled";
//import "./style.css"
import { withTranslation } from "react-i18next";
import {ROLES, Routes} from "../../../../config/routes";
import {useSelector} from "react-redux";
import {getIsBlank} from "../../../../store/settings/settingsSelector";
import {getAlertsUnread} from "../../../../store/alerts/alertsSelector";
import Restricted from "../../../../components/Permissions/Restricted";
import PermissionContext from "../../../../components/Permissions/PermissionContext";


const MainMenu = withTranslation()(({ t, match }) => {
  const isNewBlank = useSelector(getIsBlank);
  const unreadAlerts = useSelector(getAlertsUnread);
  const {isAllowedTo} = useContext(PermissionContext);

  //console.log('match', match);
  return (
    <Styled.HeaderNavMenu>
      <Styled.NavMenu theme={'dark'} mode="horizontal" defaultSelectedKeys={Routes.map(({location}) => location.pathname).filter(location => location === match.path)} style={{justifyContent: 'center'}}>
        {Routes.filter((route) =>
          route.needRole === ROLES.user
          && route.mainMenu
          && isAllowedTo(route?.permission)
        ).map(
          ({ location, name, nameToDisplay, permission, redirect }, key) => (
            /*<Restricted to={permission}>*/
              <Styled.NavMenuItem
                key={location.pathname}
              >
                <Styled.MenuLink
                  to={location}
                  target={isNewBlank && '_blank'}
                  isActive={(match, location) => {
                    if (!match) {
                      return false;
                    }
                    //console.log('menu Is active', match, location, location?.pathname?.indexOf(match?.url) >= 0)
                    return location?.pathname?.indexOf(match?.url) >= 0;
                  }}
                >
                  <i className="icon icon_folder" /> {t(nameToDisplay)}
                </Styled.MenuLink>
                {
                  name === 'Alerts' && unreadAlerts > 0 && <Styled.AlertIndicator>{unreadAlerts}</Styled.AlertIndicator>
                }
              </Styled.NavMenuItem>
            /*</Restricted>*/
          )
        )}
      </Styled.NavMenu>
    </Styled.HeaderNavMenu>
    /*<Styled.HeaderNavMenu>
      <Styled.NavMenu>
        {Routes.filter((route) => route.needRole === ROLES.user && route.mainMenu).map(
          ({ location, name, nameToDisplay }, key) => (
            <Styled.NavMenuItem
              active={match.path === location.pathname}
              key={key}
            >
              <Styled.MenuLink to={location}>
                <i className="icon icon_folder" />
                {t(nameToDisplay)}
              </Styled.MenuLink>
            </Styled.NavMenuItem>
          )
        )}
      </Styled.NavMenu>
    </Styled.HeaderNavMenu>*/
  );
});

export default withRouter(MainMenu);
