import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";
import {ImageRender} from "../../Images";

const Groups = ({t, data}) => {
  const {groups = []} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Groups")}</Text>


      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'}}>
        {
          groups.map((res, idx) =>
            <View key={idx} style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px', marginBottom: '10px'}}>
              <View>
                {res.groupImageUrl && ImageRender(res.groupImageUrl)}
              </View>
              <View>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Link src={res?.groupData?.url} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <SocialIcon network={res.socialNetwork} />
                    <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res.groupName}</Text>
                  </Link>
                </View>
                <View style={{width: '450px',}}>
                  <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res?.groupData?.membersCount} {t("members")}</Text>
                  <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res?.groupData?.history}</Text>
                  <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res?.groupData?.groupType}</Text>
                  <Text style={{fontSize:'10px', fontWeight: '600', marginBottom: '5px', fontFamily: 'pdfFont',}}>{res?.groupData?.description}</Text>
                </View>
              </View>
            </View>
          )
        }
      </View>
    </View>
  );
};

export default withTranslation()(Groups);