import ActionUtility from "../ActionUtility";

export default class UsersAction {
  static GET_USER_LIST = "UsersAction.GET_USER_LIST";
  static SET_USER_LIST = "UsersAction.SET_USER_LIST";
  static CREATE_USER = "UsersAction.CREATE_USER";
  static EDIT_USER = "UsersAction.EDIT_USER";
  static DELETE_USER = "UsersAction.DELETE_USER";
  static SET_USERS_LOADING = "UsersAction.SET_USERS_LOADING";
  static UPDATE_QUOTA = "UserAction.UPDATE_QUOTA";

  static GET_INVESTIGATIONS_LIST = "UserAction.GET_INVESTIGATIONS_LIST";
  static SET_INVESTIGATIONS_LIST = "UserAction.SET_INVESTIGATIONS_LIST";

  static ASSIGN_INVESTIGATION = "UserAction.ASSIGN_INVESTIGATION";

  static GET_QUICK_USER_LIST = "UsersAction.GET_QUICK_USER_LIST";
  static GET_USER_INFO = "UsersAction.GET_USER_INFO";
  static UPDATE_USER_STATE = "UsersAction.UPDATE_USER_STATE";

  static setInvestigationsList(data) {
    return ActionUtility.createAction(
      UsersAction.SET_INVESTIGATIONS_LIST,
      data
    );
  }

  static assignInvestigation(data, callback, userName) {
    return ActionUtility.createAction(UsersAction.ASSIGN_INVESTIGATION, {
      data,
      callback,
      userName
    });
  }

  static getInvestigaionsList(userData) {
    return ActionUtility.createAction(
      UsersAction.GET_INVESTIGATIONS_LIST,
      userData
    );
  }

  static updateQuota(data) {
    return ActionUtility.createAction(UsersAction.UPDATE_QUOTA, data);
  }

  static getUserList(data) {
    //console.log('action', data);
    return ActionUtility.createAction(UsersAction.GET_USER_LIST, data);
  }

  static setUserList(page) {
    return ActionUtility.createAction(UsersAction.SET_USER_LIST, page);
  }

  static createUser(user) {
    const {
      userName,
      password,
      admin,
      user: userRole,
      currentPage = 0,
      webintIsActive,
      profilerIsActive,
      topPostsIsActive = false,
    } = user;
    let roles = [];
    admin && roles.push("ADMIN");
    userRole && roles.push("USER");

    const newUser = {
      userName: userName,
      password,
      roles: roles,
      currentPage,
      webintIsActive,
      profilerIsActive,
      topPostsIsActive,
    };

    return ActionUtility.createAction(UsersAction.CREATE_USER, newUser);
  }

  static editUser(newInfo) {
    const {
      id,
      userName,
      password,
      admin,
      user: userRole,
      currentPage = 0,
      webintIsActive,
      profilerIsActive,
      topPostsIsActive = false,
    } = newInfo;
    let roles = [];
    admin && roles.push("ADMIN");
    userRole && roles.push("USER");

    const editedUser = {
      id,
      userName,
      password,
      roles: roles,
      currentPage,
      webintIsActive,
      profilerIsActive,
      topPostsIsActive,
    };
    return ActionUtility.createAction(UsersAction.EDIT_USER, editedUser);
  }

  static deleteUser(userData, callback) {
    return ActionUtility.createAction(UsersAction.DELETE_USER, {userData, callback});
  }

  static setUsersLoading(state) {
    return ActionUtility.createAction(UsersAction.SET_USERS_LOADING, state);
  }

  static getQuickUserList(callback) {
    return ActionUtility.createAction(
      UsersAction.GET_QUICK_USER_LIST,
      callback
    );
  }

  static getUserInfo(callback) {
    return ActionUtility.createAction(UsersAction.GET_USER_INFO, callback);
  }

  static updateUserState(action) {
    return ActionUtility.createAction(UsersAction.UPDATE_USER_STATE, action);
  }
}
