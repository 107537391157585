import React from 'react';
import {Page, Text, View, Document, Font} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
import Groups from "./Groups";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";

Font.register({
  family: 'pdfFont',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const ExportPdfWebintUserStat = ({t, data: exportData}) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>


        <Groups data={exportData} />

        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfWebintUserStat);