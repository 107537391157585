import React from "react";
import { VERSION, EXT_TITLE } from "../../../../config/consts";
import * as Styled from "./styled";
import LogoIcon from "../../../../components/UI/Icons/LogoIcon";
import config from "../../../../config/config";

const MainLogo = () => (
  <Styled.HeaderLogo>
    <Styled.NavbarBrand to="/">
      <LogoIcon
        type={config.headerLogo}
        title={`${EXT_TITLE} ${VERSION}`}
        style={{maxHeight: '56px'}}
      />
    </Styled.NavbarBrand>
  </Styled.HeaderLogo>
);

export default MainLogo;
