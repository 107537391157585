import React, {memo} from "react";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import "./style.css";
import {Layout} from "antd";
import MainWebSocket from "./MainWebSocket";

const MainLayout = memo(({children}) => {
  return (
    <>
      <Layout className="layout">
        <MainHeader/>
        {/*<Content><Styled.Wrapper>{children}</Styled.Wrapper></Content>*/}
        <>{children}</>
        <MainFooter/>
        <MainWebSocket/>
      </Layout>
    </>
  );
});

export default MainLayout;