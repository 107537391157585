import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {withTranslation} from "react-i18next";
import {SupportService} from "../../../../support";

const DeepWeb = withTranslation()(({t, data}) => {
  const { deepWeb } = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Deep Web")}</Text>

      {
        deepWeb?.hydraNewData?.map(hi => {
          return <View key={hi.id} style={{
            display: 'flex',
            flexFlow: 'row',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '10px'
          }}>
            <View>
              {
                Object.entries(hi?.display)?.map(([key, value], idx) =>
                  <View key={idx} style={{
                    display: 'flex',
                    flexFlow: 'row',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginRight: '10px'
                  }}>
                      <Text style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                        fontFamily: 'pdfFont',
                        color: '#99A3B9',
                      }}>{SupportService.getTitle(key)}</Text>
                      <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{value}</Text>
                  </View>
                )
              }
            </View>
          </View>
        })
      }
    </View>
  );
});

export default DeepWeb;