import { put, takeEvery, delay } from "redux-saga/effects";
import {
  apiAvatarsActivityService,
  apiAvatarsService,
  apiUsersService,
} from "../../config/api";
import { avatarsProfiles } from "../../mock/avatars/avatarProfiles";
import SettingsAction from "../settings/settingsAction";
import AvatarAction from "./avatarAction";
import { transformToUpdateActivity, transformUsage } from "./avatarTransformer";
import mockAiPosts from "../../mock/avatars/aiPosts.json";

function* getAllUsersSaga() {
  //TO DO USE ENDPOINT TO GET ALL USERS
  try {
    const data = yield apiUsersService.getUsers({
      isActive: true,
      pageFilter: {
        page: 0,
        size: 10000,
      },
    });
    yield put(AvatarAction.updateAvatarState({ users: data.data.content }));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* assignAvatarSaga(action) {
  const { id, username, callback } = action.payload;
  try {
    yield apiAvatarsService.updateMetaAvatarUserName(id, {
      metaAvatarId: id,
      userName: username,
    });
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getAvatarDataSaga(action) {
  const { callback } = action.payload;

  try {
    // const {data} = yield apiAvatarsService.getAvailableAvatars(/*action.payload*/);
    const data = avatarsProfiles;
    yield put(AvatarAction.setAvatarData(data));
    callback();
  } catch (e) {
    console.log(e);
  }
}

function* getBlockedAvatarsDataSaga(action) {
  const { data, callback } = action.payload;
  try {
    const blAvatar = yield apiAvatarsService.getBlockedAvatars(data.id);
    yield put(AvatarAction.setBlockedAvatarData(blAvatar.data));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* getAvatarsUsageSaga(action) {
  const { data, callback } = action.payload;
  try {
    const avatarUsage = yield apiAvatarsService.getAvatarsUsage(data.id);
    const transformedData = yield transformUsage(avatarUsage.data);
    yield put(AvatarAction.setAvatarUsage(transformedData));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

function* getAvatarActivity(action) {
  const { timeStamps, metaId, callback } = action.payload;
  try {
    const { data } = yield apiAvatarsActivityService.getScheduleByMetaAvatarId(
      metaId,
      timeStamps[0],
      timeStamps[1]
    );
    yield put(AvatarAction.setActivityEvents(data));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

function* addActivityEventAsync(action) {
  const { id, newElement, callback } = action.payload;
  try {
    console.log(newElement);
    const createdElem = yield apiAvatarsActivityService.createActivity(
      id,
      newElement
    );
    yield put(AvatarAction.addActivityEventToStore(createdElem.data));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

function* addActivityEventBulk(action) {
  const { id, activities, callback } = action.payload;
  try {
    console.log('input activities', activities);
    const createdElems = yield apiAvatarsActivityService.createActivities(
      id,
      activities
    );
    //yield put(AvatarAction.addActivityEventToStore(createdElem.data));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

function* updateActivitySaga(action) {
  const { elementToUpdate, callback, idActivity } = action.payload;
  const dataToSend = transformToUpdateActivity(elementToUpdate, idActivity);
  try {
    const { data } = yield apiAvatarsActivityService.updateActivity(
      idActivity,
      dataToSend
    );
    yield put(AvatarAction.updateActivityStore(data, idActivity));
    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* getCurrentActivity(action) {
  const { activityId, activityType, callback } = action.payload;
  try {
    const data = yield apiAvatarsActivityService.getScheduleById(
      activityId,
      activityType
    );
    console.log(data);
    yield put(AvatarAction.setCurrentActivity(data.data));
    callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

export function* deleteActivity(action) {
  console.log(action);
  const { id, type, callback } = action.payload;
  try {
    yield apiAvatarsActivityService.deleteActivity({ activity: type, id: id });
    callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback();
  }
}

function* generateAIPosts(action) {
  const { avatarId, number, callback } = action.payload;
  try {
    const {data} = yield apiAvatarsActivityService.generatePosts(
      avatarId,
      {
        //"lang": "string",
        postsData: [
          {
            numbersPost: number,
            //"sentiment": "string",
            //"percent": 0,
            //"topic": "string"
          }
        ],
        //"from": 0,
        //"to": 0,
        //"timeout": 0,
        //"openAIModel": "string",
        //"userName": "string",
        avatarId,
        //"requestTimeStamp": 0
      }
    );
    //const data = mockAiPosts;
    console.log('generateAIPosts', data);
    //yield delay(2000);
    callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    callback({});
  }
}

export function* watchAvatar() {
  yield takeEvery(AvatarAction.GET_AVATAR_USAGE, getAvatarsUsageSaga);
  yield takeEvery(AvatarAction.GET_AVATAR_DATA, getAvatarDataSaga);
  yield takeEvery(AvatarAction.GET_ACTIVITY_EVENTS_DATA, getAvatarActivity);
  yield takeEvery(
    AvatarAction.GET_BLOCKED_AVATARS_DATA,
    getBlockedAvatarsDataSaga
  );
  yield takeEvery(AvatarAction.DELETE_ACTIVITY, deleteActivity);
  yield takeEvery(AvatarAction.ADD_ACTIVITY_EVENT, addActivityEventAsync);
  yield takeEvery(AvatarAction.ADD_ACTIVITY_BULK, addActivityEventBulk);
  yield takeEvery(AvatarAction.GET_CURRENT_ACTIVITY, getCurrentActivity);
  yield takeEvery(AvatarAction.GET_ALL_USERS, getAllUsersSaga);
  yield takeEvery(AvatarAction.ASSIGN_AVATAR_TO_USER, assignAvatarSaga);
  yield takeEvery(AvatarAction.UPDATE_ACTIVITY, updateActivitySaga);
  yield takeEvery(AvatarAction.GENERATE_AI_POSTS, generateAIPosts);
}
