import EN from "../assets/img/flags/gb.svg";
import UZ from "../assets/img/flags/uz.svg";
import RU from "../assets/img/flags/ru.svg";
import SP from "../assets/img/flags/sp.svg";
import FR from "../assets/img/flags/fr.svg";
import PT from "../assets/img/flags/pt.svg";

import i18n from "i18next";
const env = window?._env_ || process.env;

const envLanguages = (env?.REACT_APP_LANGUAGES || '').split(',');

const locales = {
  languages: [
    {code: 'en', title: i18n.t('English'), icon: EN,},
    {code: 'ru', title: i18n.t('Russian'), icon: RU,},
    {code: 'uz', title: i18n.t('Uzbek'), icon: UZ,},
    {code: 'uz_cyrl', title: i18n.t('Uzbek Cyrillic'), icon: UZ,},
    {code: 'sp', title: i18n.t('Spanish'), icon: SP,},
    {code: 'fr', title: i18n.t('French'), icon: FR,},
    {code: 'pt', title: i18n.t('Portuguese'), icon: PT,},
  ].filter(({code}) => envLanguages.includes(code))
}

export default locales;