import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  ExternalHyperlink
} from "docx";
import i18n from "i18next";
import moment from "moment";
import {SupportService} from "../../../support";
import DocxService from "../DocxService";
import {capitalize} from "../../../../helpers/capitalize";

class ExportDocxWebint {
  static dimensions = {};

  static renderTitlePage = ({task}) => {
    const {imageId, name, type} = task;

    let mainImage = new Paragraph({
      text: "\n\n\n\n\n\n\n\n",
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
    });

    if (imageId) {
      mainImage = new Paragraph({
        children: [
          DocxService.createImage(imageId, {}, true)
        ],
        alignment: AlignmentType.CENTER,
      });
    }

    return {
      ...DocxService.createFooter(),
      children: [
        mainImage,
        new Paragraph({
          text: (name || '') + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: type + "\n\n\n\n\n\n\n\n",
          heading: HeadingLevel.HEADING_3,
          alignment: AlignmentType.CENTER,
        }),
      ],
    };
  }

  static renderTargets = ({targets = []}) => {
    const targetRows = [];

    targets.forEach(({socialNetwork, profileImageUrl, profileName, profileId, avatar = null}) => {
      targetRows.push(new Paragraph({
        children: [
          DocxService.createImage(avatar || profileImageUrl, {width: 50, height: 50}, false)
        ]
      }));
      targetRows.push(DocxService.createColumns(
          [
            {text: profileName, bold: true},
            {text: socialNetwork},
          ], 2000
        )
      );
      targetRows.push(new Paragraph({
        children: [
          new ExternalHyperlink({
            children: [
              new TextRun({
                text: profileId,
                style: "Hyperlink",
              }),
            ],
            link: SupportService.getSocialProfileUrl(socialNetwork, profileId),
          }),
          DocxService.createEmptyRow(),
        ]
      }))
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Targets')),
        ...targetRows
      ]
    };
  };

  static renderGroups = ({groups = []}) => {
    const targetRows = [];

    groups.forEach(({socialNetwork, groupImageUrl, groupName, groupId, groupData = {}}) => {
      targetRows.push(new Paragraph({
        children: [
          DocxService.createImage(groupImageUrl, {width: 50, height: 50}, false)
        ]
      }));
      targetRows.push(DocxService.createColumns(
          [
            {text: groupName, bold: true},
            {text: socialNetwork},
          ], 2000
        )
      );
      targetRows.push(new Paragraph({
        children: [
          new ExternalHyperlink({
            children: [
              new TextRun({
                text: groupId,
                style: "Hyperlink",
              }),
            ],
            link: groupData?.url,
          }),
          DocxService.createEmptyRow(),
        ]
      }))
      if (groupData?.history) {
        targetRows.push(new Paragraph({
          text: groupData.history
        }));
      }
      if (groupData?.membersCount) {
        targetRows.push(new Paragraph({
          text: `${groupData.membersCount} ${i18n.t("members")}`
        }));
      }
      if (groupData?.groupType) {
        targetRows.push(new Paragraph({
          text: capitalize(groupData.groupType)
        }));
      }
      targetRows.push(DocxService.createEmptyRow());

      targetRows.push(new Paragraph({
        text: SupportService.stripHtmlTags(groupData.description)
      }));
      targetRows.push(DocxService.createEmptyRow());
      targetRows.push(DocxService.createEmptyRow());

    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Groups')),
        ...targetRows
      ]
    };
  };

  static renderPosts = ({posts = []}) => {
    const postsContent = [];

    posts.forEach(post => {
      let postText = post.text;
      if (post.isSharedPost) {
        postText = `${i18n.t("Shared post")}: ${post.sharedText}`
      }
      postsContent.push(new Paragraph({
        text: moment(post.timeStamp).format('YYYY-MM-DD HH:mm:ss'),
        italics: true,
      }));
      postsContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(postText)
      }));
      postsContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: post.postUrl,
                  style: "Hyperlink",
                }),
              ],
              link: post.postUrl,
            }),
          ]
        })
      );
      postsContent.push(DocxService.createColumns(
          [
            {text: `${i18n.t("Likes")}:`},
            {text: post?.totalReactionsCount || '0', bold: true},
          ], 2000
        )
      );
      postsContent.push(DocxService.createColumns(
          [
            {text: `${i18n.t("Comments")}:`},
            {text: post?.commentsCount || '0', bold: true},
          ], 2000
        )
      );
      postsContent.push(DocxService.createColumns(
          [
            {text: `${i18n.t("Auditory")}:`},
            {text: (post?.commentsCount * 1 + post?.totalReactionsCount * 1) || '0', bold: true},
          ], 2000
        )
      );
      postsContent.push(DocxService.createColumns(
          [
            {text: `${i18n.t("Resonance")}:`},
            {text: post?.resonanceScore || '0', bold: true},
          ], 2000
        )
      );
      postsContent.push(DocxService.createEmptyRow());
      postsContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Posts')),
        ...postsContent,
      ],
    };
  };

  static renderTopPosts = ({topPosts = []}) => {
    const postsContent = [];

    topPosts.forEach(post => {
      let postText = post.text;
      if (post.isSharedPost) {
        postText = `${i18n.t("Shared post")}: ${post.sharedText}`
      }
      postsContent.push(new Paragraph({
        text: moment(post.timeStamp).format('YYYY-MM-DD HH:mm:ss'),
        italics: true,
      }));
      postsContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(postText)
      }));
      postsContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: post.postUrl,
                  style: "Hyperlink",
                }),
              ],
              link: post.postUrl,
            }),
          ]
        })
      );
      postsContent.push(DocxService.createEmptyRow());
      postsContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Top Posts')),
        ...postsContent,
      ],
    };
  };

  static renderPhotos = ({images = []}) => {
    const imageRows = [];

    images.forEach(({thumbnailLink}) => {
      imageRows.push(DocxService.createImage(thumbnailLink, {width: 270, height: 270}, false))
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Photos')),
        new Paragraph({
          children: imageRows,
        }),
      ],
    }
  };

  static renderVideos = ({videos = []}) => {
    const tResults = [];
    videos.forEach(({resultLink, thumbnailLink, searchEngine, resultPreview, resultTitle, videoCommentsCount, videoLikesCount}) => {
      tResults.push(new Paragraph({
        children: [DocxService.createImage(thumbnailLink, {width: 100, height: 60}, false)]
      }));
      tResults.push(new Paragraph({
        text: searchEngine,
        heading: HeadingLevel.HEADING_5,
      }));
      tResults.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: resultTitle,
                  style: "Hyperlink",
                }),
              ],
              link: resultLink,
            }),
          ]
        })
      );
      tResults.push(new Paragraph({
        text: SupportService.stripHtmlTags(resultPreview)
      }));
      tResults.push(DocxService.createColumns(
          [
            {text: `${i18n.t("Likes")}: ${videoLikesCount}`},
            {text: `${i18n.t("Comments")}: ${videoCommentsCount}`},
          ], 2000
        )
      );

      tResults.push(new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
      }));
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading(i18n.t('Videos')),
        ...tResults,
      ],
    }
  };

  /*static renderSearchResults = ({searchProgress}) => {
    const {searchResults = []} = searchProgress;

    const tResults = [];
    searchResults.map(({avatar, socialNetwork, profileId, profileName}) => {
      tResults.push(new Paragraph({
        children: [DocxService.createImage(avatar, {width: 50, height: 50}, false)]
      }));
      tResults.push(new Paragraph({
        text: profileName,
        heading: HeadingLevel.HEADING_5,
      }));
      tResults.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: profileId,
                  style: "Hyperlink",
                }),
              ],
              link: SupportService.getSocialProfileUrl(socialNetwork, profileId),
            }),
          ]
        })
      );
      tResults.push(new Paragraph({
        text: '',
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
      }));
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading('Search Results'),
        ...tResults,
      ],
    }
  };

  static renderWebResults = ({webResults = []}) => {
    const webContent = [];

    webResults.map(({resultTitle, resultPreview, searchEngine, resultLink}) => {
      webContent.push(new Paragraph({
        text: resultTitle,
        bold: true,
        heading: HeadingLevel.HEADING_3,
      }));
      webContent.push(DocxService.createEmptyRow());
      webContent.push(new Paragraph({
        text: SupportService.stripHtmlTags(resultPreview)
      }));
      webContent.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: resultLink,
                  style: "Hyperlink",
                }),
              ],
              link: resultLink,
            }),
          ]
        })
      );
      webContent.push(DocxService.createEmptyRow());
    })

    return {
      ...DocxService.createFooter(),
      children: [
        DocxService.createHeading('Web Results'),
        ...webContent,
      ],
    }
  };*/


  static renderDoc(data) {
    const {sections = []} = data;

    const docSections = [
      this.renderTitlePage(data),
    ]
    if (sections.includes('targets')) {
      docSections.push(this.renderTargets(data));
    }
    if (sections.includes('groups')) {
      docSections.push(this.renderGroups(data));
    }
    if (sections.includes('posts')) {
      docSections.push(this.renderPosts(data));
    }
    if (sections.includes('topPosts')) {
      docSections.push(this.renderTopPosts(data));
    }
    if (sections.includes('photos')) {
      docSections.push(this.renderPhotos(data));
    }
    if (sections.includes('videos')) {
      docSections.push(this.renderVideos(data));
    }

    return new Document({
      sections: docSections,
    });
  }
}

export default ExportDocxWebint;