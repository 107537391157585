import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  margin: 0 5px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

const SocialIcon = (
  {
    type,
    text,
    title = null,
    size = '',
    style,
    styleText,
    styleImg,
  }) => {
    const images = require.context('../../../../assets/img/socials', true);
    try{
      const img = images('./' + (size ? `${size}/` : '') + type.toLowerCase() + '.png');
      return <Container style={style}>
        <img
          alt={type}
          title={title ? title : type}
          src={img.default}
          style={{width: size ? `${size}px` : '16px', ...styleImg}}
        />
        {
          !!text && <TextContainer className={'social-icon-text'} style={styleText}>{text}</TextContainer>
        }
      </Container>;
    } catch (e) {
      return <Container style={style}>
        {
          !!text && <TextContainer className={'social-icon-text'} style={styleText}>{text}</TextContainer>
        }
      </Container>;
    }
};

export default SocialIcon;