import moment from "moment";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import {SupportService} from "../../../support";

export class ExportXlsWebint {
  static exportTask = ({task}) => {
    const data = [
      ['ID', task.id],
      ['Name', task.name],
      ['Type', task.type],
      ['Keywords', task?.keywords?.map(({name}) => name).join(', ')],
      ['Image URL', imageByIndex(task.imageId)],
    ];

    return data;
  }

  static exportTargets = ({targets = []}) => {
    const data = [
      ['Profile ID', 'Name', 'Social Network', 'Type', 'Profile Image'],
    ];

    targets.forEach(({profileId, socialNetwork, profileName, type, profileImageUrl}) => {
      data.push([profileId, profileName, socialNetwork, type, profileImageUrl]);
    })

    return data;
  }

  static exportGroups = ({groups = []}) => {
    const data = [
      ['Group ID', 'Group Name', 'Social Network', 'Type', 'URL', 'Members', 'History', 'Image URL'],
    ];

    groups.forEach(({groupId, groupName, socialNetwork, groupData, groupImageUrl}) => {
      data.push([groupId, groupName, socialNetwork, groupData.groupType, groupData.url, groupData.membersCount, groupData.history, groupImageUrl]);
    })

    return data;
  }

  static exportPosts = ({posts}) => {
    const data = [
      ['Social Network', 'Profile ID', 'Profile Name', 'Published Date', 'Text', 'Shared Text', 'Likes', 'Comments', 'Reactions', 'Resonance', 'Sentiment', 'Link'],
    ];

    posts.forEach(({socialNetwork, originalPostUid, originalPostUserName, timeStamp, text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment, postUrl}) => {
      data.push([socialNetwork, originalPostUid, originalPostUserName, moment(timeStamp).format("YYYY/MM/DD HH:mm"), text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment?.sentiment, postUrl]);
    })

    return data;
  }

  static exportTopPosts = ({topPosts}) => {
    const data = [
      ['Social Network', 'Profile ID', 'Profile Name', 'Published Date', 'Text', 'Shared Text', 'Likes', 'Comments', 'Reactions', 'Resonance', 'Sentiment', 'Link'],
    ];

    topPosts.forEach(({socialNetwork, originalPostUid, originalPostUserName, timeStamp, text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment, postUrl}) => {
      data.push([socialNetwork, originalPostUid, originalPostUserName, moment(timeStamp).format("YYYY/MM/DD HH:mm"), text, sharedText, likeCount, commentsCount, totalReactionsCount, resonanceScore, sentiment?.sentiment, postUrl]);
    })

    return data;
  }

  static exportImages = ({images = []}) => {
    const data = [
      ['Source', 'Keyword', 'Image URL'],
    ];

    images.forEach(({searchEngine, keyword, imageUrl}) => {
      data.push([searchEngine, keyword, imageUrl]);
    })

    return data;
  }

  static exportVideos = ({videos = []}) => {
    const data = [
      ['Source', 'Keyword', 'Title', 'Description', 'Likes', 'Comments', 'Link'],
    ];

    videos.forEach(({searchEngine, keyword, resultTitle, resultPreview, videoLikesCount, videoCommentsCount, resultLink}) => {
      data.push([searchEngine, keyword, resultTitle, SupportService.stripHtmlTags(resultPreview), videoLikesCount, videoCommentsCount, resultLink]);
    })

    return data;
  }

}