import { put, takeEvery } from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import RekognitionAction from "./rekognitionAction";
import {apiRekognitionService} from "../../config/api";
import {
  transformFaceDetect,
  transformFaceEnroll,
  transformFaceSearch,
  transformFaceSearchProfiles
} from "./rekognitionTransformer";
import {notification} from "antd";
import i18n from "i18next";

function* detectSaga(action) {
  const {image, callback} = action.payload;

  try {
    const {data} = yield apiRekognitionService.detectFace({url: image});
    //console.log('detectSaga', data);
    //const transData = transformPersonalInfoData(data);
    //yield put(RekognitionAction.updateProfilerState({personalInfo: transData}));
    //yield delay(2000);

    //console.log('mock data', detectFacesMockData);
    //const transData = transformFaceDetect(detectFacesMockData)

    const transData = transformFaceDetect(data)
    yield callback(transData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* searchSaga(action) {
  const {image, profileId = null, callback} = action.payload;

  try {

    //console.log('mock search data', searchFacesMockData);
    //const {data} = yield apiRekognitionService.search({url: image, profileId: profileId});
    const {data} = yield apiRekognitionService.searchProfiles({url: image, profileId: profileId});

    //const data = searchFacesMockData;
    //const transData = transformFaceSearch(data)
    const transData = transformFaceSearchProfiles(data)
    //yield put(RekognitionAction.updateProfilerState({personalInfo: transData}));
    //yield delay(2000);
    yield callback(transData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* enrollSaga(action) {
  const {targetId, image, callback} = action.payload;

  try {
    const {data} = yield apiRekognitionService.enroll({targetId, url: image});
    const transData = transformFaceEnroll(data)
    notification.success({message: i18n.t('Image successfully saved'), placement: 'bottomRight'});
    yield callback(transData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* deleteSaga(action) {
  const {targetId, image, callback} = action.payload;

  try {
    const {data} = yield apiRekognitionService._delete({targetId, url: image});
    console.log('deleteSaga', data);
    //const transData = transformFaceEnroll(data)
    notification.success({message: i18n.t('Image successfully deleted'), placement: 'bottomRight'});
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* recognizeFacesSaga(action) {
  const {targetId, callback} = action.payload;

  try {
    const {data} = yield apiRekognitionService.analyzeOptional(targetId);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

export function* watchRekognition() {
  yield takeEvery(RekognitionAction.DETECT, detectSaga);
  yield takeEvery(RekognitionAction.SEARCH, searchSaga);
  yield takeEvery(RekognitionAction.ENROLL, enrollSaga);
  yield takeEvery(RekognitionAction.DELETE, deleteSaga);

  yield takeEvery(RekognitionAction.RECOGNIZE_FACES, recognizeFacesSaga);
}
