import React, { useCallback, useEffect, useState } from "react";
import { Menu, Popover, Switch } from "antd";
import * as Styled from "./styled";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../store/auth/authSelector";
import LangSelector from "../../../../components/UI/LangSelector";
import { Link } from "react-router-dom";
import { getIsBlank } from "../../../../store/settings/settingsSelector";
import SettingsAction from "../../../../store/settings/settingsAction";
import {withTranslation} from "react-i18next";
import Restricted from "../../../../components/Permissions/Restricted";
import usePermission from "../../../../components/Permissions/usePermission";
import config from "../../../../config/config";

const UserMenu = ({t}) => {
  const dispatch = useDispatch();
  const isNewBlank = useSelector(getIsBlank);
  const currentUser = useSelector(getUser);
  const [menuVisible, setMenuVisible] = useState(false);
  const [username, setUserName] = useState("");
  const [moduleWebint] = usePermission('module.webint');
  const [moduleTopPosts] = usePermission('module.top_posts');
  const [headerLogout] = usePermission('header.logout');

  const setIsNewBlank = useCallback(
    (value) => {
      dispatch(SettingsAction.toggleNewBlank(value));
    },
    [dispatch]
  );

  const switchHandler = (event) => {
    localStorage.setItem("ui_isNewTab", event);
    setIsNewBlank(event);
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.roles.includes('ADMIN')) {
        setUserName(currentUser.username);
      } else {
        setUserName(config.headerUsername || currentUser.username);
      }
    }
  }, [currentUser]);

  const UserMenu = () => (
    <Styled.UserMenuContainer>
      <Restricted to={'header.languages'}>
        <LangSelector />
      </Restricted>
      <Menu defaultOpenKeys={["sub1"]} mode="inline" style={{ border: "none" }}>
        <Restricted to={'header.new_tab'}>
          <Styled.SwitchBlock>
            <Switch
              size="small"
              defaultChecked={isNewBlank}
              onChange={switchHandler}
            />
            <Styled.SwitchName>{t("Open in new tab")}</Styled.SwitchName>
          </Styled.SwitchBlock>
        </Restricted>
        {currentUser?.roles?.includes("ADMIN") && (
          <Menu.Item>
            <Link to={"/users"} target={isNewBlank ? "_blank" : ""}>
              {t("User management")}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.roles?.includes("ADMIN") && (
          <Menu.Item>
            <Link to={"/audit"} target={isNewBlank ? "_blank" : ""}>
              {t("Audit")}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.topPostsIsActive && moduleWebint && moduleTopPosts && (
          <Menu.Item>
            <Link to={"/top-posts"} target={isNewBlank ? "_blank" : ""}>
              {t("Top Posts")}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.roles?.includes("ADMIN") && (
          <Menu.Item>
            <Link to={"/user-stat"} target={isNewBlank ? "_blank" : ""}>
              {t("Users Stats")}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.roles?.includes("ADMIN") && (
          <Menu.Item>
            <Link to={"/proxy"} target={isNewBlank ? "_blank" : ""}>
              {t("Proxy Management")}
            </Link>
          </Menu.Item>
        )}
        {
          config.headerUserManual && <Menu.Item>
            <Link to={{pathname:`${config.headerUserManual}`}} target={"_blank"}>
              {t("Help")}
            </Link>
          </Menu.Item>
        }
        {
          headerLogout && <Menu.Divider />
        }
        {
          headerLogout && <Menu.Item>
            <Link to={"/logout"}>{t("Logout")}</Link>
          </Menu.Item>
        }
      </Menu>
    </Styled.UserMenuContainer>
  );

  return (
    <Styled.HeaderAvatar>
      <Styled.HeaderUserName>
        {username.charAt(0).toUpperCase() + username.slice(1)}
      </Styled.HeaderUserName>
      <Popover
        content={<UserMenu />}
        title={
          <Styled.UserMenuTitle>
            {username.charAt(0).toUpperCase() + username.slice(1)}
          </Styled.UserMenuTitle>
        }
        trigger="click"
        visible={menuVisible}
        onVisibleChange={() => setMenuVisible(!menuVisible)}
      >
        <a>
          <Styled.UserAvatar>
            {username && username[0].toUpperCase()}
          </Styled.UserAvatar>
        </a>
      </Popover>
    </Styled.HeaderAvatar>
  );
};

export default withTranslation()(UserMenu);
