import moment from "moment";

export const transformAvatarSomeData = (info) => {
  return info;
};

export const transformToUpdateActivity = (
  elementToUpdate,
  idActivity
) => {
  return {
    ...elementToUpdate,
    id: idActivity
  }
};

export const transformUsage = (reqData) => {
  // TO DO REMOVE THIS MOCK AFTER REAL DATA WILL COME
  // const newElem = {
  //   avatarDto: {
  //     id: "61fba3121f8e0b09f76dee7e",
  //     username: "CUSTOM ELEMNT",
  //     password: "123",
  //     domain: "FACEBOOK",
  //     status: "QUARANTINE",
  //     statusMessage: "STATUS CHANGED",
  //     proxy: {
  //       custom: null,
  //       country: "DK",
  //       domain: null,
  //       ipAddress: null,
  //       port: null,
  //       login: null,
  //       password: null,
  //       proxyType: null,
  //     },
  //     userAgent: "",
  //     additionalInfo: {
  //       apiKey: null,
  //       apiSecret: null,
  //       apiAccessToken: null,
  //       apiAccessTokenSecret: null,
  //       apiAppId: null,
  //       apiRefreshToken: null,
  //       apiSecretKey: null,
  //     },
  //     useAlerts: true,
  //     useQuarantine: true,
  //     quarantineInfo: null,
  //     updatedStatus: null,
  //     module: "PROFILER",
  //     assignedTaskIds: [],
  //   },
  //   dailyUsage: { "2022-02-09": 4, "2022-02-10": 29 },
  //   moduleUsage: { PROFILER: 4, WEBINT: 6 },
  // };

  // const second = {
  //   avatarDto: {
  //     id: "61fba3121f8e0b09f76dee7e",
  //     username: "CUSTOM AVATAR 2",
  //     password: "123",
  //     domain: "VIBER",
  //     status: "QUARANTINE",
  //     statusMessage: "STATUS CHANGED",
  //     proxy: {
  //       custom: null,
  //       country: "DK",
  //       domain: null,
  //       ipAddress: null,
  //       port: null,
  //       login: null,
  //       password: null,
  //       proxyType: null,
  //     },
  //     userAgent: "",
  //     additionalInfo: {
  //       apiKey: null,
  //       apiSecret: null,
  //       apiAccessToken: null,
  //       apiAccessTokenSecret: null,
  //       apiAppId: null,
  //       apiRefreshToken: null,
  //       apiSecretKey: null,
  //     },
  //     useAlerts: true,
  //     useQuarantine: true,
  //     quarantineInfo: null,
  //     updatedStatus: null,
  //     module: "PROFILER",
  //     assignedTaskIds: [],
  //   },
  //   dailyUsage: { "2022-02-08": 1, "2022-02-09": 7, "2022-02-10": 32 },
  //   moduleUsage: { PROFILER: 72, WEBINT: 65 },
  // };

  // reqData.push(second);
  // reqData.push(newElem);
  const finalObject = {
    profiler: 0,
    webint: 0,
    chartData: [],
    avatars: [],
  };
  finalObject.profiler = reqData.map((el) => {
    return {
      username: el.avatarDto.username,
      value: el.moduleUsage.PROFILER || 0,
    };
  });

  finalObject.webint = reqData.map((el) => {
    return {
      username: el.avatarDto.username,
      value: el.moduleUsage.WEBINT || 0,
    };
  });

  const chartDataArray = [];
  reqData.forEach((elFull) => {
    Object.keys(elFull.dailyUsage).forEach((key) => {
      const chartArrayElem = {
        fieldName: elFull.avatarDto.username,
        date: moment(key, "YYYY-MM-DD").format("DD-MM-YYYY"),
        value: elFull.dailyUsage[key],
      };
      chartDataArray.push(chartArrayElem);
    });
  });

  //finalObject.chartData = chartDataArray;
  /*finalObject.chartData = chartDataArray.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );*/

  //normalize series:
  const dateVariants = [...new Set(chartDataArray.map(tag => tag.date))].sort(
    (a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY")
  );
  const normalizedDateVariants = [];
  const normalizedChartData = [];
  const startDate = dateVariants[0];
  const endDate = dateVariants[dateVariants.length - 1];
  console.log('s-e', dateVariants, startDate, endDate);

  let iterationDate = startDate;
  while (iterationDate !== endDate) {
    normalizedDateVariants.push(iterationDate);
    iterationDate = moment(iterationDate, "DD-MM-YYYY").add(1, 'day').format("DD-MM-YYYY");
  }
  const userVariants = reqData.map((el) => el.avatarDto.username);

  normalizedDateVariants.forEach(day => {
    userVariants.forEach(user => {
      const found = chartDataArray.find(el => el.date === day && el.fieldName === user);
      if (found) {
        normalizedChartData.push(found);
      } else {
        normalizedChartData.push({
          fieldName: user,
          date: day,
          value: 0
        });
      }
    })
  });
  finalObject.chartData = normalizedChartData;


  console.log('finalObject.chartData', finalObject.chartData);
  console.log('normalizedDateVariants', normalizedDateVariants);
  console.log('userVariants', userVariants);
  console.log('normalizedChartData', normalizedChartData);

  finalObject.avatars = reqData.map((el) => {
    return {
      sn: el.avatarDto.domain,
      username: el.avatarDto.username,
    };
  });
  return finalObject;
};

export const transformAIPosts = (aiPosts = []) => {
  const outData = [];
  let randDates = [];

  aiPosts.forEach((aiPost, idx) => {
    randDates.push(
      moment(new Date(+(new Date()) + Math.floor(Math.random()*1000000000)))
    )
  })
  randDates = randDates.sort((a, b) => a > b ? 1 : -1)
  console.log('randDates', randDates);

  aiPosts.forEach((aiPost, idx) => {
    outData.push({
      id: idx,
      date: randDates[idx].format("DD-MM-YYYY"),
      files: null,
      text: aiPost.content,
      time: randDates[idx],
      topic: aiPost.topic,
      sentiment: aiPost.sentiment,
    })
  })
  console.log('preparedPosts', outData)
  return outData;
};
