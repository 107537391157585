import moment from "moment";

export class ExportXlsAvatarActivities {
  static exportActivities = (activities = []) => {
    const data = [
      ['ID', 'Activity', 'Domain', 'Date', 'Time', 'Login', 'Post', 'URL', 'Status'],
    ];

    activities.forEach(({id, activity, domain, timestamp, login, text, url, status}) => {
      data.push([
        id,
        activity,
        domain,
        moment(timestamp, 'x').format("DD-MM-YYYY"),
        moment(timestamp, 'x').format("HH:ss"),
        login,
        text,
        url,
        status,
      ]);
    })

    return data;
  }

}