import {Image} from "@react-pdf/renderer";
import noAvatar from "../../../../assets/img/dummy/noavatar1.png";

export const ImageRender = (src, style = {}) => {
  let imageBlock;
  if (!src?.trim()) {
    //console.log('src empty');
    return <Image style={{width: '50px', height: '50px', marginRight: '10px', ...style}} src={noAvatar}/>;
  }

  try {
    imageBlock = <Image style={{width: '50px', height: '50px', marginRight: '10px', ...style}} src={src}/>;
    //console.log('success', src);
  } catch (e) {
    console.log('e', e);
    console.log('error', src);
    imageBlock = <Image style={{width: '50px', height: '50px', marginRight: '10px', ...style}} src={noAvatar}/>;
  }

  return imageBlock;
}