import React from "react";

import * as Styled from "./styled";
import "./style.css";

import MainLogo from "./MainLogo";
import MainMenu from "./MainMenu";
import LangSelector from "../../../components/UI/LangSelector";

import { Layout} from 'antd';
import UserMenu from "./UserMenu";
const { Header} = Layout;

const MainHeader = () => (
  <Header id={'topnav'}>
    <MainLogo />
    <MainMenu />
    <UserMenu />
    {/*<LangSelector />*/}
  </Header>
  /*<Styled.Header id="top-nav">
    <Styled.HeaderMenu>
      <MainLogo />
      <MainMenu />
      <LangSelector />
    </Styled.HeaderMenu>
  </Styled.Header>*/
);

export default MainHeader;