import styled from "styled-components";
import ArrowToTop from "../../../assets/img/arrow_top.png";

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 15px;  
`;

export const Container = styled.div`
  
`;

export const Copyright = styled.div`
  
`;

export const Logo = styled.p`
  
`;

export const ToTopButton = styled.button`
  background: transparent url(${ArrowToTop}) no-repeat center;
  border: 0;
  cursor: pointer;
  height: 14px;
  width: 14px;
  position: fixed;
  bottom: 10px;
  right: 30px;
  z-index: 99;
`;
