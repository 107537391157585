import React from 'react';
import {Page, Text, View, Document, Font} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
import MainPage from "./MainPage";
import Targets from "./Targets";
import Groups from "./Groups";
import Posts from "./Posts";
import TopPosts from "./TopPosts";
import Images from "./Images";
import Videos from "./Videos";

Font.register({
  family: 'pdfFont',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});




const ExportPdfWebint = ({t, data}) => {
  const { sections = [] } = data;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>


        <MainPage data={data} />
        <Text break/>

        { sections.includes('targets') && <><Targets data={data} /><Text break/></> }
        { sections.includes('groups') && <><Groups data={data} /><Text break/></> }
        { sections.includes('posts') && <><Posts data={data} /><Text break/></> }
        { sections.includes('topPosts') && <><TopPosts data={data} /><Text break/></> }
        { sections.includes('photos') && <><Images data={data} /><Text break/></> }
        { sections.includes('videos') && <><Videos data={data} /><Text break/></> }


        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfWebint);