import React, { memo } from "react";
import * as Styled from "./styled";

const LoginLayout = memo((props) => {
  return (
    <>
      <Styled.Content>
        {props.children}
      </Styled.Content>
    </>
  );
});

export default LoginLayout;
