import React from 'react';
import {Image, Text, View} from "@react-pdf/renderer";
import {styles} from "../styles";
import {imageByIndex} from "../../../../../helpers/imageByIndex";

const MainPage = ({data}) => {
  const { task } = data;
  const {imageId, name, type} = task;

  return (
    <View style={{marginTop: "100px"}}>
      {
        imageId &&
        <Image
          style={styles.image}
          src={imageByIndex(imageId)}
        />
      }
      <Text style={styles.title}>{name}</Text>
      <Text style={styles.phone}>{type}</Text>
    </View>
  );
};

export default MainPage;