import ActionUtility from "../ActionUtility";

export default class SettingsAction {
  static SET_IS_LOADING = "SettingsAction.SET_IS_LOADING";
  static UPDATE_STATE = "SettingsAction.UPDATE_STATE";
  static SHOW_NOTIFICATION = "SettingsAction.SHOW_NOTIFICATION";
  static CLEAR_NOTIFICATIONS = "SettingsAction.CLEAR_NOTIFICATIONS";
  static HANDLE_API_ERROR = "SettingsAction.HANDLE_API_ERROR";
  static HANDLE_API_CUSTOM_MESSAGE = "SettingsAction.HANDLE_API_CUSTOM_MESSAGE";
  static SET_ACTIVE_ROUTE = "SettingsAction.SET_ACTIVE_ROUTE";
  static SET_LANGUAGE = "SettingsAction.SET_LANGUAGE";
  static TOGGLE_NEW_BLANK = "SettingsAction.TOGGLE_NEW_BLANK"

  static INIT_LAYOUT = "SettingsAction.INIT_LAYOUT";
  static SET_LAYOUT = "SettingsAction.SET_LAYOUT";
  static GET_LAYOUT = "SettingsAction.GET_LAYOUT";
  static ADD_LAYOUT_ITEM = "SettingsAction.ADD_LAYOUT_ITEM";
  static DELETE_LAYOUT_ITEM = "SettingsAction.DELETE_LAYOUT_ITEM";

  static handleAPIErrorCustom(reactNode, type, tostConfig) {
    return ActionUtility.createAction(SettingsAction.HANDLE_API_ERROR_CUSTOM, {reactNode, type, tostConfig})  
  }

  static updateState(fieldsToUpdate) {
    return ActionUtility.createAction(
      SettingsAction.UPDATE_STATE,
      fieldsToUpdate
    );
  }

  static  toggleNewBlank(value) {
    return ActionUtility.createAction(SettingsAction.TOGGLE_NEW_BLANK, value)
  }

  static setIsLoading(state) {
    return ActionUtility.createAction(SettingsAction.SET_IS_LOADING, state);
  }

  static showNotification(message, type) {
    return ActionUtility.createAction(SettingsAction.SHOW_NOTIFICATION, {
      message,
      type
    });
  }

  static clearNotifications() {
    return ActionUtility.createAction(SettingsAction.CLEAR_NOTIFICATIONS);
  }

  static handleAPIError(error) {
    return ActionUtility.createAction(SettingsAction.HANDLE_API_ERROR, error);
  }

  static setActiveRoute(route) {
    return ActionUtility.createAction(SettingsAction.SET_ACTIVE_ROUTE, route);
  }

  static setLanguage(language) {
    return ActionUtility.createAction(SettingsAction.SET_LANGUAGE, language);
  }

  static initLayout(keyLayout, defaultLayout, keyStorage) {
    console.log("defaultLayout", defaultLayout);
    return ActionUtility.createAction(SettingsAction.INIT_LAYOUT, {
      keyLayout,
      defaultLayout,
      keyStorage,
    });
  }

  static setLayout(keyLayout, items) {
    return ActionUtility.createAction(SettingsAction.SET_LAYOUT, {
      keyLayout,
      items,
    });
  }

  static getLayout(keyLayout) {
    return ActionUtility.createAction(SettingsAction.GET_LAYOUT, keyLayout);
  }

  static addLayoutItem(keyLayout, item) {
    return ActionUtility.createAction(SettingsAction.ADD_LAYOUT_ITEM, {
      keyLayout,
      item,
    });
  }

  static deleteLayoutItem(keyLayout, item) {
    return ActionUtility.createAction(SettingsAction.DELETE_LAYOUT_ITEM, {
      keyLayout,
      item,
    });
  }
}
